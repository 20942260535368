@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow: hidden;
}

button {
  background-color: intial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box !important;
}

.ant-btn.ant-btn-primary {
  background-color: #1890ff;
}

.ant-popover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}
